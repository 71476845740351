(function () {
  'use strict';

  angular
    .module('neo.public')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('public', {
        abstract: true,
        url: '/public',
        templateUrl: 'authentication/authentication.tpl.html'
      });
  }
}());
